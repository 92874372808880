<template>
  <div justify="end">
    <v-dialog v-model="isVisibleDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mx-5"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("import_csv") }}
        </v-btn>
      </template>
      <v-form
        ref="form"
        v-model="is_valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("products csv") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        dense
                        outlined
                        @change="onFileChanged($event)"
                        type="file"
                        label="csv"
                        prepend-icon=""
                        append-icon="mdi-file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-alert
                    v-if="errors.length > 0"
                    type="error"
                    dense
                    outlined
                    class="pa-2 mb-0"
                  >
                    <li
                      v-for="(error, key) in errors"
                      :key="key"
                    >
                      {{ error }}
                    </li>
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              :disabled="is_loading"
              :loading="is_loading"
              @click="exportTemplate()"
            >
              {{ $t("download_excel_template") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="uploadCSV(products)"
              :disabled="!is_valid"
              :loading="is_uploading"
            >
              {{ $t("upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import service from "@/store/services/product-alert-service";

export default {
  mixins: [validationRules],

  data() {
    return {
      errors: [],
      products: {},
      is_valid: true,
      is_loading: false,
      is_uploading: false,
      isVisibleDialog: false,
    };
  },
  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.errors = [];
      this.isVisibleDialog = false;
      (this.is_uploading = false), (this.products = {});
    },

    async uploadCSV(products) {
      if (this.$refs.form.validate()) {
        try {
          this.is_uploading = true;
          await this.$store.dispatch("products/uploadAlertsCSV", products);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.is_uploading = false;
          await this.$store.dispatch("badges/getProductsAlert", "stock");
          await this.$store.dispatch("badges/getProductsAlert", "expiry");
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_uploading = false;
          if (error.response?.data?.message) {
            this.errors = error.response?.data?.message?.split("\n");
          }
        }
        this.is_uploading = false;
      }
    },

    async exportTemplate() {
      this.is_loading = true;
      service
        .exportCSVTemplate()
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "product-alerts.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading = false;
        });
    },

    onFileChanged(e) {
      this.products.csv = e;
    },
  },
};
</script>
